<template>
  <div class="grid">
    <Toast />
    <Toolbar class="col-12">
      <template #start>
        <Button
          :label="$t('button.save')"
          icon="pi pi-save"
          class="p-button-primary mr-2"
          @click="onSave"
          v-if="receipt.id"
        />
        <Button
          :label="$t('button.print')"
          icon="pi pi-eye"
          class="p-button-primary mr-2"
          @click="onPrintPreview"
          v-if="receipt.id"
        />
        <Button
          v-if="this.receipt.id && receipt.packageNumber > 1"
          :label="$t('button.generate')"
          icon="pi pi-list"
          class="p-button-primary mr-2"
          @click="onGenerateContent"
        />
        <Button
          :label="$t('button.back')"
          icon="pi pi-backward"
          @click="$router.back()"
        />
      </template>
      <template #center v-if="receipt.id">
        <h4>
          {{ formatDate(receipt.createdDate) }} - {{ receipt.receiptCode }} -
          {{ receipt.packageNumber }} Pcs - {{ receipt.weight }} Lbs /
          {{ tokg(receipt.weight) }} Kg - Fr: {{ receipt.senderName }} -> To:
          {{ receipt.recipientName }}
        </h4>
      </template>
      <!-- <template #end>
            <Button :label="$t('button.addPackage')" icon="pi pi-plus" @click="onAddPackage"/>
          </template> -->
    </Toolbar>
    <!-- <div class="md:col-12">
        <div class="field grid">
          <label for="receipt" class="mb-2 md:col-1 md:mb-0">{{$t('receipt.receiptCode')}}</label>
          <AutoComplete id="receipt" class="md:col-3" v-model="receipt.receiptCode" field="receiptCode"
            :suggestions="filteredReceipts"
            @item-select="onReceiptSelect"
            @complete="searchReceipt($event)" placeholder="Hint: type receipt code"
            :dropdown="true">
            <template #item="slotProps">
              <div>{{slotProps.item.receiptCode}}-{{slotProps.item.senderName}}</div>
            </template>
          </AutoComplete>
          <label for="packageNumber" class="mb-2 md:col-1 md:mb-0">{{$t('receipt.packageCounter')}}</label>
          <div class="md:col-2">
            <InputNumber id="packageNumber" v-model="receipt.packageNumber" showButtons buttonLayout="horizontal"
              incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
              :min="1" :step="1"
              inputStyle="width: 80px"
              @input="onChangePackageNumber($event)"/>
          </div>
          <label for="weight" class="mb-2 md:mb-0">{{$t('package.weight')}}</label>
          <div class="md:col-2 flex">
            <InputNumber id="weight" v-model="receipt.weight" mode="decimal" :maxFractionDigits="2" disabled/>
            <span class="p-inputgroup-addon">{{$t('unit.lbs')}}</span>
          </div>
          <div class="md:col-1 flex">
            <InputNumber id="weight-kg" v-model="weightKg" mode="decimal" :min="0" :maxFractionDigits="2" disabled/>
            <span class="p-inputgroup-addon">{{$t('unit.kg')}}</span>
          </div>
        </div>
        <div class="field grid">
          <div class="col-offset-1 md:col-10 grid">
            <Button :label="$t('button.save')" icon="pi pi-save" class="p-button-primary mr-2" @click="onSave" v-if="receipt.id"/>
            <Button :label="$t('button.print')" icon="pi pi-eye" class="p-button-primary mr-2" @click="onPrintPreview" v-if="receipt.id"/>
            <Button :label="$t('button.shipping_form')" icon="pi pi-eye" class="p-button-primary mr-2" @click="onPreviewShippingForm" v-if="receipt.id"/>
            <Button :label="$t('button.shipping_label')" icon="pi pi-eye" class="p-button-primary mr-2" @click="onPreviewShippingLabel" v-if="receipt.id"/>
            <Button v-if="this.receipt.id && receipt.packageNumber > 1" :label="$t('button.generate')" icon="pi pi-list" class="p-button-primary mr-2" @click="onGenerateContent" />
            <Button :label="$t('button.back')" icon="pi pi-backward" @click="$router.back()"/>
          </div>
        </div>
      </div> -->
    <DataTable
      ref="dt"
      :value="packageItems"
      dataKey="id"
      :paginator="false"
      :showGridlines="true"
      :scrollable="true"
      scrollDirection="both"
      responsiveLayout="scroll"
      class="col-12"
    >
      <!-- <template #header>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 class="m-0">{{$t('packageItem.packing')}}</h5>
          <Button :label="$t('button.add')" icon="pi pi-plus" @click="onAddPackage"/>
        </div>
      </template> -->
      <Column
        field="electronic"
        :header="$t('packageItem.declare')"
        frozen
        headerStyle="width:80px;"
      >
        <template #body="{ data, field }">
          <Checkbox
            v-model="data[field]"
            binary
            :disabled="true"
            style="width: 52px"
          />
        </template>
      </Column>
      <Column
        field="name"
        :header="$t('packageItem.name')"
        :sortable="true"
        frozen
        headerStyle="width:300px;"
      >
        <template #body="slotProps">
          <span class="p-column-title">{{ $t("packageItem.name") }}</span>
          <div style="width: 272px">
            {{ slotProps.data.name }}
          </div>
        </template>
      </Column>
      <Column
        field="total_quantity"
        :header="$t('packageItem.total_quantity')"
        :sortable="true"
        frozen
        alignFrozen="left"
        headerStyle="width:80px;"
      >
        <template #body="slotProps">
          <span class="p-column-title">{{
            $t("packageItem.total_quantity")
          }}</span>
          <div style="width: 52px">
            {{ slotProps.data.quantity }}
          </div>
        </template>
      </Column>
      <!-- Unpacking items -->
      <Column
        :frozen="true"
        field="unpacked"
        headerStyle="width:90px;padding:0rem 1rem;font-size:10px"
        :style="{ textAlign: 'center' }"
      >
        <template #header>
          <div class="flex-column">
            <h6 class="flex">{{ $t("packageItem.unpacked") }}</h6>
            <div class="p-inputgroup">
              <InputNumber
                v-model="unpackedWeight"
                :maxFractionDigits="2"
                class="flex"
                :disabled="true"
                :inputStyle="{ width: '60px' }"
              />
              <span class="p-inputgroup-addon">{{
                "Lbs/" + tokg(unpackedWeight) + "kg"
              }}</span>
            </div>
          </div>
        </template>
        <template #body="{ data, field }">
          <div style="width: 62px">
            <InputNumber
              v-model="data[field]"
              :inputStyle="{ width: '60px' }"
              :disabled="true"
            />
          </div>
        </template>
      </Column>
      <!-- <Column v-for="(value, key, index) in weights" :key="index" :field="key" :frozen="false" headerStyle="width:90px;padding:0rem 1rem;font-size:10px;"> -->
      <Column
        v-for="item in weights"
        :key="item.code"
        :field="item.code"
        :frozen="false"
        headerStyle="width:90px;padding:0rem 1rem;font-size:10px;"
      >
        <template #header="{}">
          <!-- <div class="p-inputgroup">
              <span class="p-inputgroup-addon">{{key}}</span>
              <InputNumber v-model="weights[column.props.field]" :maxFractionDigits="2" inputStyle="width:50px"/>
          </div> -->
          <div class="flex-column">
            <Button
              :label="item.code"
              icon="pi pi-trash"
              class="flex p-button-danger"
              tabindex="-1"
              :disabled="!item.editable || item.code != lastRemoveable"
              @click="() => onDeletePackage(item.code)"
            />
            <div class="p-inputgroup">
              <InputNumber
                v-model="item.weight"
                :maxFractionDigits="2"
                class="flex"
                :inputStyle="{ width: '60px' }"
                :disabled="!item.editable"
                @blur="handleWeightBlur($event, key)"
              />
              <span class="p-inputgroup-addon">{{
                "Lbs/" + tokg(item.weight) + "kg"
              }}</span>
            </div>
          </div>
        </template>
        <template #body="{ data, field }">
          <div style="width: 62px">
            <InputNumber
              v-model="data[field].quantity"
              :ref="field + data.id"
              :min="0"
              :disabled="!data[field].editable"
              @blur="handleItemQtyBlur($event, data, field)"
              :inputStyle="{ width: '60px' }"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ReceiptService from "@/service/ReceiptService";
//import PackageService from '@/service/PackageService'
import { converter } from "@/utils/common";
import formatter from "@/utils/formatter";
//import adjustment from '@/utils/adjustment'
import Consts from "@/utils/consts";
import { writeFile, utils } from "xlsx";

export default {
  components: {},
  data() {
    return {
      allReceipts: [],
      filteredReceipts: [],
      receipt: {},
      receiptCode: null,
      packageItems: [],
      packageCode: null,
      selectedPackage: null,
      weights: [],
      packageCounter: 10,
    };
  },
  computed: {
    weightKg() {
      return converter.tokg(this.receipt?.weight);
    },
    unpackedWeight() {
      let total = this.weights.reduce(
        (total, item) => total + (item.weight || 0),
        0
      );
      return this.receipt.weight - total;
    },
    lastRemoveable() {
      for (let i = this.weights.length - 1; i >= 0; i--) {
        if (this.weights[i].editable) {
          return this.weights[i].code;
        }
      }
      return null;
    },
  },
  mounted() {
    let { receiptId } = this.$route.params;
    if (receiptId) {
      ReceiptService.get(receiptId).then((response) => {
        this._setReceipt(response);
      });
    }
    // ReceiptService.getUnshippedReceipt().then(response => {
    //   this.allReceipts = response;
    //   if (this.$route.params.receiptCode) {
    //     let receipts = response.filter(item => item.receiptCode == this.$route.params.receiptCode);
    //     if (receipts.length > 0) {
    //       this._setReceipt(receipts[0]);
    //     }
    //   }
    // });
  },
  methods: {
    searchReceipt(event) {
      let query = event.query;
      setTimeout(() => {
        if (!query.trim().length) {
          this.filteredReceipts = [...this.allReceipts];
        } else {
          let filter = query.toLowerCase();
          this.filteredReceipts = this.allReceipts.filter((value) => {
            return (
              value.receiptCode.toLowerCase().includes(filter) ||
              value.senderName.toLowerCase().includes(filter)
            );
          });
        }
      }, 100);
    },
    onReceiptSelect(event) {
      this._setReceipt(event.value);
    },
    formatDate(date) {
      return formatter.formatDate(date);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    editablePackage(pkg) {
      return pkg && (pkg.shipment == null || pkg.shipment.status == "inited");
    },
    _getPackageCode(i) {
      if (i >= 0 && i < this.receipt.packageDtos.length) {
        let pkg = this.receipt.packageDtos[i];
        let code = pkg.packageCode.split("-")[1];
        return code;
      }
      return "";
    },
    _setReceipt(receipt) {
      this.receipt = receipt;
      this.packageNumber = this.receipt.packageNumber;
      let weights = [];
      for (let i = 0; i < this.receipt.packageDtos?.length; i++) {
        let pkg = this.receipt.packageDtos[i];
        let code = pkg.packageCode.split("-")[1];
        weights.push({
          code,
          weight: pkg.weightLbs,
          editable: this.editablePackage(pkg),
        });
      }
      this.weights = weights;
      this._createPackageItems();
    },
    _createPackageItems() {
      //create packageItems
      let itemQuantities = {};
      for (let i = 0; i < this.receipt.packageDtos?.length; i++) {
        let field = this._getPackageCode(i);
        let pkg = this.receipt.packageDtos[i];
        let map = {};
        if (Array.isArray(pkg.itemDtos)) {
          for (let j = 0; j < pkg.itemDtos.length; j++) {
            let item = pkg.itemDtos[j];
            map[item.name] = item.quantity;
          }
        }
        itemQuantities[field] = map;
      }
      this.packageItems = this.receipt.packageItemDtos?.map((item) => {
        let editItem = { ...item };
        editItem.unpacked = item.quantity;
        if (this.receipt.packageDtos?.length == 1) {
          let pkg = this.receipt.packageDtos[0];
          let code = pkg.packageCode.split("-")[1];
          editItem.unpacked = 0;
          editItem[code] = {
            quantity: item.quantity,
            editable: this.editablePackage(pkg),
          };
        } else if (this.receipt.packageDtos?.length > 1) {
          editItem.unpacked = item.quantity;
          for (let i = 0; i < this.receipt.packageDtos?.length; i++) {
            let pkg = this.receipt.packageDtos[i];
            let code = pkg.packageCode.split("-")[1];
            editItem[code] = editItem[code] || {};
            let map = itemQuantities[code] || {};
            editItem[code].quantity =
              map[item.name] || editItem[code].quantity || 0;
            editItem[code].editable = this.editablePackage(pkg);
            editItem.unpacked -= editItem[code].quantity;
          }
        }
        return editItem;
      });
      /*
      if (this.receipt.packageNumber < Consts.DEFAULT_PACKAGE_NUMBER) {
        this.packageNumber = Consts.DEFAULT_PACKAGE_NUMBER;
      } else {
        this.packageNumber = this.receipt.packageNumber;
      }
      */
    },
    onChangePackageNumber(event) {
      this.receipt.packageNumber = event.value;
      this._initPackages();
    },
    onAddPackage() {
      this.receipt.packageNumber++;
      this._initPackages();
    },
    _initPackages() {
      let weights = [];
      this.packageNumber = this.receipt.packageNumber;
      if (this.packageNumber == 1) {
        let code = this._getPackageCode(0);
        weights.push({ code, weight: this.receipt.weight });
      } else {
        for (let i = 0; i < this.packageNumber; i++) {
          let code = this._getPackageCode(i);
          if (i < this.weights.length) {
            weights[i] = this.weights[i];
          } else {
            weights[i] = { code, weight: 0 };
          }
        }
      }
      this.weights = weights;
      this._createPackageItems();
    },
    onDeletePackage(packageCode) {
      if (this.receipt.id && packageCode) {
        ReceiptService.removePackage(this.receipt.id, packageCode).then(
          (resp) => {
            if (resp.data.code == 0) {
              this.weights = this.weights.filter(
                (item) => item.code != packageCode
              );
              this.$toast.add({
                severity: "success",
                summary: this.$t("receipt.remove_package"),
                detail: this.$t("receipt.remove_package_successfully", [
                  packageCode,
                ]),
                life: 3000,
              });
            }
          }
        );
      }
    },
    handleWeightBlur() {
      let total = this.weights.reduce((total, item) => total + item.weight, 0);
      if (this.receipt.weight < total) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error.weight_invalid"),
          detail: this.$t("error.total_weight_exceeded", [
            total,
            this.receipt.weight,
          ]),
          life: 3000,
        });
      }
    },
    handleItemQtyBlur(event, data, field) {
      let curValue = 0; //elm = this.$refs[field + data.id][0];
      if (event.value) {
        curValue = parseInt(event.value);
      } else {
        curValue = 0;
      }
      data[field].quantity = curValue || 0;
      let total = 0;
      for (var i = 0; i < this.packageNumber; i++) {
        let code = this._getPackageCode(i);
        total += data[code]?.quantity || 0;
      }
      data.unpacked = data.quantity - total;

      if (data.unpacked < 0) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error.quantity_invalid"),
          detail: this.$t("error.total_quantity_exceeded", [
            data.name,
            data.quantity,
          ]),
          life: 3000,
        });
      }
    },
    onPrintPreview() {
      const routeData = this.$router.resolve({
        name: "print_preview",
        params: { resource: "receipt", id: this.receipt.id },
      });
      window.open(routeData.href);
      // if (this.$route.params.receiptId) {
      //   let receipts = response.filter(item => item.id == this.$route.params.receiptId);
      //   if (receipts.length > 0) {
      //     this._setReceipt(receipts[0]);
      //   }
      // }
      // ReceiptService.findByCode(entity.receiptCode).then(data => {
      //     if (Array.isArray(data) && data.length == 1) {
      //       const receiptId = data[0].id;
      //       //this.$router.push({ name: 'print_preview_shippinglabel', params: {resource: "receipt", id: receiptId, packageId: entity.id} });
      //       const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "receipt", id: receiptId, packageId: entity.id} });
      //       //this.openPreview(routeData.href);
      //       window.open(routeData.href);
      //     }
      //   });
    },
    onPreviewShippingLabel() {
      this.$router.push({
        name: "print_preview_shippinglabel",
        params: {
          resource: "receipt",
          id: this.receipt.id,
        },
      });
    },
    onPreviewShippingForm() {
      this.$router.push({
        name: "print_preview_shippingform",
        params: {
          resource: "receipt",
          id: this.receipt.id,
        },
      });
    },
    handlePackageEditor(index, event) {
      console.log(index);
      console.log(event);
    },
    onSave() {
      let errors = this.verify();
      if (errors.length == 0) {
        this._doSave();
      } else {
        this.$confirm.require({
          message: errors.join(",\n"),
          header: this.$t("package.errors"),
          icon: "pi pi-exclamation-triangle",
          style: { width: "50vw" },
          accept: () => {
            //cal lback to execute when user accept the action
          },
          reject: () => {
            //cal lback to execute when user rejects the action
          },
        });
      }
    },
    _doSave() {
      //Create packages
      let packageEntities = [];
      for (let i = 0; i < this.packageNumber; i++) {
        let code = this._getPackageCode(i);
        let packageDto = {
          weightLbs: this.weights[i].weight,
          weightUnit: "lbs",
          packageCode: this._createPackageCode(packageEntities.length + 1),
          receiptCode: this.receipt.receiptCode,
          packingStatus: 2,
          itemDtos: [],
        };
        for (let n = 0; n < this.packageItems.length; n++) {
          let item = this.packageItems[n];
          if (item[code].quantity > 0) {
            packageDto.itemDtos.push({
              packageCode: packageDto.packageCode,
              name: item.name,
              electronic: item.electronic,
              quantity: item[code].quantity,
              description: item.description,
            });
          }
        }
        packageEntities.push(packageDto);
      }
      ReceiptService.savePackages(
        this.receipt.receiptCode,
        packageEntities
      ).then((res) => {
        this.$toast.add({
          severity: "success",
          summary: this.$t("common.action_successfull"),
          detail: this.$t("receipt.packages_saved_successfull"),
          life: 3000,
        });
        console.log(res);
      });
    },
    _createPackageCode(index) {
      let res = "",
        v = index,
        i = 0;
      while (v > 0 || i < Consts.PACKGE_CODE_LENGTH) {
        res = (v % 10) + res;
        v = Math.floor(v / 10);
        i++;
      }
      return this.receipt.receiptCode + "-" + res;
    },
    verify() {
      let errors = [];
      //let total = this.weights.reduce((total, item)=> total + item.weight, 0);
      //Check package weight
      for (let i = 0; i < this.packageItems.length; i++) {
        let item = this.packageItems[i];
        if (item.unpackedWeight < 0) {
          errors.push(
            this.$t("error.total_quantity_exceeded", [item.name, item.quantity])
          );
        }
      }
      let totalWeight = 0;
      //Check empty package
      for (let i = 0; i < this.packageNumber; i++) {
        let code = this._getPackageCode(i);
        let itemCounter = this.packageItems.reduce(
          (total, item) => total + (item[code].quantity > 0 ? 1 : 0),
          0
        );
        if (!this.weights[i].weight) {
          errors.push(this.$t("error.weight_required", [code]));
        }
        if (itemCounter == 0) {
          errors.push(this.$t("error.package_empty", [code]));
        }
        totalWeight += this.weights[i].weight || 0;
      }
      //Check total weight
      let diffWeight = Math.abs(this.receipt.weight - totalWeight);
      console.log(Consts.MAX_WEIGHT_DIFF);
      if (
        diffWeight > Consts.MAX_WEIGHT_DIFF &&
        (diffWeight / this.receipt.weight) * 100 >
          Consts.MAX_WEIGHT_DIFF_IN_PERCENTILE
      ) {
        errors.push(this.$t("error.weight_not_matched"));
      }
      return errors;
    },
    onGenerateContent() {
      //weights
      /*
      let keys = Object.keys(this.weights);
      let ratios = [], sum = 0, weights = {};
      for(let i = 0; i < keys.length; i++) {
        ratios[i] = Math.random();
        sum += ratios[i];
      }
      for(let i = 0; i < ratios.length; i++) {
        let field = 'p' + (i+1);
        weights[field] = ratios[i] / sum * this.receipt.weight;
      }
      this.weights = weights;
      */
      let packageItems = [];
      for (var i = 0; i < this.packageItems.length; i++) {
        var pkgItem = { ...this.packageItems[i] };
        pkgItem.unpacked = 0;
        for (let j = 0; j < this.packageNumber; j++) {
          let code = this._getPackageCode(j);
          pkgItem[code] = { quantity: 0, editable: true };
        }
        packageItems.push(pkgItem);
        for (let j = 0; j < pkgItem.quantity; j++) {
          //Set random packageCode
          let ind = Math.floor(Math.random() * this.packageNumber);
          let code = this._getPackageCode(ind);
          pkgItem[code].quantity = pkgItem[code].quantity + 1;
        }
      }
      this.packageItems = packageItems;
    },
    onExportManifest() {
      console.log(this.allPackages[1]);
      // export json to Worksheet of Excel
      // only array possible
      //Map receiptCode to receiptObject
      let receipts = {};
      let totalWeight = 0;
      this.allPackages[1].forEach((pkg) => {
        totalWeight += pkg.weightLbs;
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg);
        if (receipt) {
          receipts[pkg.receiptCode] = receipt;
        }
      });
      const exportData = Object.values(receipts)
        .sort((a, b) => a.receiptCode < b.receiptCode)
        .map((receipt) => ({
          [this.$t("receipt.receiptCode")]: receipt.receiptCode,
          [this.$t("receipt.senderName")]:
            receipt.dSenderName || receipt.senderName,
          [this.$t("receipt.senderAddress")]:
            receipt.dSenderAddress || receipt.senderAddress,
          [this.$t("receipt.senderPhone")]:
            receipt.dSenderPhone || receipt.senderPhone,
          [this.$t("receipt.recipientName")]:
            receipt.dRecipientName || receipt.recipientName,
          [this.$t("receipt.recipientAddress")]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [this.$t("receipt.recipientPhone")]:
            receipt.dRecipientPhone || receipt.recipientAddress,
          [this.$t("receipt.number_of_packages")]: receipt.packageNumber,
          [this.$t("receipt.weightLbs")]: receipt.weightLbs,
          [this.$t("receipt.description")]: receipt.content,
        }));
      var packages = utils.json_to_sheet(exportData, {
        origin: "A3",
      });
      utils.sheet_add_aoa(packages, [[this.$t("shipment.manifestTitle")]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(
        packages,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: this.allPackages[1].length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        packages,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      // A workbook is the name given to an Excel file
      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, packages, "Packages"); // sheetAName is name of Worksheet

      // export Excel file
      writeFile(wb, "shipment_packages.xlsx"); // name of the file is 'book.xlsx'
    },
    _addPackage(receipt, pkg) {
      if (!receipt && this.shipment && this.shipment.receipts) {
        for (let i = 0; i < this.shipment.receipts.length; i++) {
          if (this.shipment.receipts[i].receiptCode == pkg.receiptCode) {
            receipt = {
              ...this.shipment.receipts[i],
              packageNumber: 0,
              weightLbs: 0,
              content: "",
            };
            break;
          }
        }
      }
      if (receipt) {
        receipt.packageNumber += 1;
        receipt.weightLbs += pkg.weightLbs;
        if (!receipt.content) {
          receipt.content = this._getPackageContent(pkg);
        } else {
          receipt.content += "," + this._getPackageContent(pkg);
        }
      }
      return receipt;
    },
    //Todo:
    _getPackageContent(pkg) {
      return pkg.description;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-header {
  width: 6%;
  min-width: 80px;
  padding: 1rem 0.1rem;
  font-size: 10;
}
</style>
